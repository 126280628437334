import React, { useEffect, useState } from 'react';
import {
    Checkbox,
    Grid,
    TextField,
    FormControlLabel,
    Paper,
    Button,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Box,
} from '@mui/material';
import moment from "moment";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import axios from 'axios';
import util from './util';
const DownloadPage = (props) => {
    const [projectType, setProjectType] = useState('HST');
    const [includeRawData, setIncludeRawData] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [selectedProject, setSelectedProject] = useState('');
    const [downloadText, setDownloadText] = useState('Download');
    useEffect(() => {
        axios.post(util.getUrl() + "getProjectInfoList", { headers: { "Auth": props.loginToken } }).then((res) => {
            let _projectList = res.data.data;
            // console.log("projectList:" + JSON.stringify(_projectList));
            let filteredProjectList = [];
            for (let i = 0; i < _projectList.length; i++) {
                if (props.userProjectList.includes(_projectList[i].projectID) || props.userType == "ADMIN") {
                    // console.log("Adding project:" + _projectList[i]);
                    filteredProjectList.push(_projectList[i]);
                }
            }
            setProjectList(filteredProjectList);
            // console.log("Setting selected project:" + filteredProjectList[0])
            if(selectedProject == '') {
                setSelectedProject(filteredProjectList[0].projectID);
            }
        });
    }, []);

    let firstTitle = true;

    const getDDTPercentCorrect = (side, results) => {
        let itemCount = 0;
        let correctCount = 0;

        //object to hold all the counts for 1-9
        let digitItemCounts = {};
        let digitCorrectCounts = {};
        for (let i = 1; i <= 9; i++) {
            digitItemCounts[i] = 0;
            digitCorrectCounts[i] = 0;
        }
        for (let i = 0; i < results.length; i++) {
            let numberSetLeft = results[i]["numberSetLeft"];
            let numberSetRight = results[i]["numberSetRight"];
            let userInput = results[i]["userInput"];

            if (side == "left" || side == "both") {
                for (let q = 0; q < numberSetLeft.length; q++) {
                    itemCount++;
                    digitItemCounts[numberSetLeft[q]] = digitItemCounts[numberSetLeft[q]] + 1;

                    if (userInput.includes(numberSetLeft[q])) {
                        correctCount++;
                        digitCorrectCounts[numberSetLeft[q]] = digitCorrectCounts[numberSetLeft[q]] + 1;
                    }
                }
            }
            if (side == "right" || side == "both") {
                for (let q = 0; q < numberSetRight.length; q++) {
                    itemCount++;
                    digitItemCounts[numberSetRight[q]] = digitItemCounts[numberSetRight[q]] + 1;
                    if (userInput.includes(numberSetRight[q])) {
                        correctCount++;
                        digitCorrectCounts[numberSetRight[q]] = digitCorrectCounts[numberSetRight[q]] + 1;
                    }
                }
            }
        }
        return { pct: (correctCount * 100 / itemCount).toFixed(2), correctCount: correctCount, itemCount: itemCount, digitCorrectCounts: digitCorrectCounts, digitItemCounts: digitItemCounts };
    }
    //side can be both, left, or right
    const checkDDTCorrect = (side, numberSetLeft, numberSetRight, userInput) => {
        let isCorrect = true;
        if (side) {
            if (userInput.length != 4) {
                isCorrect = false;
            } else {
                for (let q = 0; q < userInput.length; q++) {
                    if (!(numberSetLeft.includes(userInput[q]) || numberSetRight.includes(userInput[q]))) {
                        isCorrect = false;
                        break;
                    }
                }
            }
        } else {//if not both
            if (userInput.length != 2) {
                isCorrect = false;
            } else {
                let userInput = userInput;
                for (let q = 0; q < userInput.length; q++) {
                    if (side) {
                        if (!(numberSetLeft.includes(userInput[q]))) {
                            isCorrect = false;
                            break;
                        }
                        // } else {
                        if (!(numberSetRight.includes(userInput[q]))) {
                            isCorrect = false;
                            break;
                        }
                    }
                }
            }
        }
        return isCorrect;
    }
    //rule for checking if the result is correct.  The order does not matter, but must contain all digits.
    const checkDINCorrect = (numberSet, userInput) => {
        let isCorrect = true;
        if (userInput.length == numberSet.length) {
            let userInputSorted = userInput.sort();
            let numberSetSorted = numberSet.sort();
            for (let q = 0; q < userInputSorted.length; q++) {
                if (userInputSorted[q] != numberSetSorted[q]) {
                    isCorrect = false;
                    break;
                }
            }
        } else {
            isCorrect = false;
        }
        return isCorrect;
    }
    //find the average SNR for DIN adaptive.  
    const getDINAverageSNR = (startIdx, endIdx, results) => {
        if(results.length==0) return 0;

        let snrCount = 0;
        let snrTotal = 0;
        for (let i = startIdx; i <= endIdx; i++) {
            snrCount++;
            
            snrTotal += results[i]['outputdB'] - 65;
        }
        return (snrTotal / snrCount).toFixed(2);
    }
    //getting the percentage correct from start index to end index (start from 0)
    const getDINPercentageCorrect = (startIdx, endIdx, results) => {
        let correctCount = 0;
        let itemCount = 0
        for (let i = startIdx; i <= endIdx; i++) {
            itemCount++;
            let isCorrect = checkDINCorrect(results[i]['numberSet'], results[i]['userInput']);
            if (isCorrect) correctCount++;
        }
        return (correctCount * 100 / itemCount).toFixed(2);
    }
    //not quite efficient, could try something later
    //see if a digit in certain position would be correct. For example, the digit 9 in position 1 for 9-3-4
    const getDINPercentageCorrectDigitPosition = (digitToTest, digitPosition, results) => {
        let correctCount = 0;
        let itemCount = 0;

        for (let i = 0; i < 27; i++) {
            let isCorrect = checkDINCorrect(results[i]['numberSet'], results[i]['userInput']);
            //change from the position info (1,2,3) to index in the array by -1
            if (results[i]['numberSet'][digitPosition - 1] == digitToTest) {
                if (isCorrect) correctCount++;
                itemCount++;
            }
        }
        //avoid divide by zero
        return itemCount == 0 ? "--" : (correctCount * 100 / itemCount).toFixed(2);
    }
    const getHTTPassAmplitude = (results, index) => {
        console.log("getHTTPassAmplitude results:" + JSON.stringify(results));
        let resultsSorted = results.sort((a, b) => parseInt(a["frequency"]) > parseInt(b["frequency"]) ? 1 : -1);
        return resultsSorted[index]["threshold"];
    }
    const processRefNumber = (refNo) => {
        //pad refNo with 0 to 4 digits
        let refNoPadded = refNo.toString().padStart(4, '0');
        return '="' + refNoPadded + '"';
    }
    //MARK: getAverageReactionTime
    const getAverageReactionTime = (results) => {
        console.log("Get avage reaction time:" + JSON.stringify(results) + " type:" + typeof (results) + " length:" + results.length);
        let totalReactionTime = 0;
        let correctCount = 0;
        for (let i = 0; i < results.length; i++) {
            let isCorrect = checkDINCorrect(results[i]['numberSet'], results[i]['userInput']);
            if (isCorrect) {
                totalReactionTime += parseFloat(results[i]['reactionTime']);
                correctCount++;
            }
        }
        return totalReactionTime / correctCount;
    }

    const renderTestResult = (testRow) => {
        console.log("Test Type:" + testRow['testType']);
        if (testRow['testType'] === "HST" || testRow['testType'] === "CHST") {
            console.log(JSON.stringify(testRow));
            let titles = ["ProjectID", "Ref No.", "Test Date", "Test", "Ear"];

            let rowCsv = "";
            if (firstTitle) {
                for (let i = 0; i < titles.length; i++) {
                    rowCsv += titles[i] + ",";
                }
                rowCsv += "\n";

                if (!includeRawData) {
                    firstTitle = false;
                }
            }

            rowCsv += testRow['projectID'] + ",";
            rowCsv += `${processRefNumber(testRow['refNo'])},`;
            rowCsv += moment(Math.floor(testRow['testDate'])).format("YYYY/MM/DD HH:mm") + ",";
            rowCsv += testRow['testType'] + ",";

            let testInfo = JSON.parse(testRow['testInfo']);
            rowCsv += testInfo['ear'] + ",";
            rowCsv += "\n";
            if (includeRawData) {
                let results = JSON.parse(testRow["results"]);
                console.log(results);
                results = results.sort((a, b) => parseInt(a["frequency"]) > parseInt(b["frequency"]) ? 1 : -1);

                rowCsv += `,Frequency, Responses, Pass Test\n`;

                for (let i = 0; i < results.length; i++) {
                    let respondSeq = results[i]["respondSequence"]
                    let respondSeqText = "";
                    for (let s = 0; s < respondSeq.length; s++) {
                        respondSeqText += respondSeq[s]['dB'] + " " + (respondSeq[s]['heardTone'] ? "Y" : "X") + "_";
                    }
                    rowCsv += `, ${results[i]["frequency"]}, ${respondSeqText}, ${results[i]["passTest"] ? "Y" : "X"}\n`;
                }
                rowCsv += "\n";
            }
            return rowCsv;
        } else if (testRow['testType'] === "HTT" || testRow['testType'] === "CHTT") {

            let testInfo = JSON.parse(testRow['testInfo']);
            let results = JSON.parse(testRow["results"]);
            let titles = ["ProjectID", "Ref No.", "Test Date", "Test", "Ear"];
            let frequencies = ["250Hz", "500Hz", "1000Hz", "1000Hz_2", "2000Hz", "4000Hz", "8000Hz"];
            if (testRow['testType'] === "CHTT") {
                frequencies = ["500Hz", "1000Hz", "2000Hz", "4000Hz"];
            }
            let sides = ["R", "L"];
            let side = testInfo['ear'] == "Right Ear" ? "R" : "L";

            for (let s = 0; s < sides.length; s++) {
                for (let f = 0; f < frequencies.length; f++) {
                    titles.push(sides[s] + "_" + frequencies[f]);
                }
            }
            let rowCsv = "";
            if (firstTitle) {
                for (let i = 0; i < titles.length; i++) {
                    rowCsv += titles[i] + ",";
                }
                rowCsv += "\n";

                if (!includeRawData) {
                    firstTitle = false;
                }
            }
            rowCsv += testRow['projectID'] + ",";
            rowCsv += `${processRefNumber(testRow['refNo'])},`;
            rowCsv += moment(Math.floor(testRow['testDate'])).format("YYYY/MM/DD HH:mm") + ",";
            rowCsv += testRow['testType'] + ",";
            rowCsv += (side == "R" ? "Right" : "Left") + ",";
            if (side == "L") {
                for (let f = 0; f < frequencies.length; f++) {
                    rowCsv += ",";
                }
            }
            for (let f = 0; f < frequencies.length; f++) {
                rowCsv += getHTTPassAmplitude(results, f) + ",";
            }
            if (side == "R") {
                for (let f = 0; f < frequencies.length; f++) {
                    rowCsv += ",";
                }
            }

            rowCsv += "\n";
            results = results.sort((a, b) => parseInt(a["frequency"]) > parseInt(b["frequency"]) ? 1 : -1);
            if (includeRawData) {
                rowCsv += `,Frequency,Threshold, Responses\n`;

                for (let i = 0; i < results.length; i++) {
                    let respondSeq = results[i]["respondSequence"]
                    let respondSeqText = "";
                    for (let s = 0; s < respondSeq.length; s++) {
                        respondSeqText += respondSeq[s]['dB'] + " " + (respondSeq[s]['heardTone'] ? "Y" : "X") + " / ";
                    }
                    rowCsv += `, ${results[i]["frequency"]},${results[i]["threshold"]}, ${respondSeqText}\n`;
                }
                rowCsv += "\n";
            }
            return rowCsv;
        } else if (((testRow['testType']) === "DIN" && (projectType == "DIN_FIXED")) || ((testRow['testType']) === "CDIN" && (projectType == "DIN_FIXED"))) {
            //MARK: DIN_FIXED
            let results = JSON.parse(testRow["results"]);    

            let titles = ["ProjectID", "Ref No.", "Test Date", "Test Type", "Response mode", "Test Ear", "Test List", "Noise level (dB HL)",
                "Signal level (db HL)", "SNR", "Percent correct 1-27", "Percent correct 5-27", "Percent correct 5-19",
                "Percent correct 5-14", "Percent correct 5-9", "Avg Reaction Time"];

            if (testRow['testType'] === "CDIN") {
                titles = ["ProjectID", "Ref No.", "Test Date", "Test Type", "Response mode", "Test Ear", "Test List", "Noise level (dB HL)",
                    "Signal level (db HL)", "SNR", "Percent correct 1-12", "Percent correct 5-12", "Avg Reaction Time"];
            }

            for (let digitToTest = 1; digitToTest <= 9; digitToTest++) {
                for (let digitPosition = 1; digitPosition <= 3; digitPosition++) {

                    titles.push("%P" + digitPosition + "_" + digitToTest);
                }
            }
            let rowCsv = "";
            if (firstTitle) {
                for (let i = 0; i < titles.length; i++) {
                    rowCsv += titles[i] + ",";
                }
                rowCsv += "\n";

                if (!includeRawData) {
                    firstTitle = false;
                }
            }

            rowCsv += testRow['projectID'] + ",";
            rowCsv += `${processRefNumber(testRow['refNo'])},`;
            rowCsv += moment(Math.floor(testRow['testDate'])).format("YYYY/MM/DD HH:mm") + ",";
            let testInfo = JSON.parse(testRow['testInfo']);
            // console.log("testInfo:" + JSON.stringify(testInfo));

            rowCsv += testInfo['TestType'] + ",";

            rowCsv += "Optimization" + ",";
            rowCsv += testInfo['TestEar'] + ",";
            rowCsv += testInfo['TestList'] + ",";
            rowCsv += "65,"; //Noise level (db HL)
            rowCsv += testInfo['TestdB'] + ",";
            rowCsv += (testInfo['TestdB'] - 65) + ",";
            //get % with index from and to

            if (testRow['testType'] === "CDIN") {
                console.log("Result length:" + results.length);
                rowCsv += getDINAverageSNR(0, 11, results) + ",";
                rowCsv += getDINAverageSNR(4, 11, results) + ",";
            } else {
                rowCsv += getDINPercentageCorrect(0, 26, results) + ",";
                rowCsv += getDINPercentageCorrect(4, 26, results) + ",";
                rowCsv += getDINPercentageCorrect(4, 18, results) + ",";
                rowCsv += getDINPercentageCorrect(4, 13, results) + ",";
                rowCsv += getDINPercentageCorrect(4, 8, results) + ",";
            }

            for (let digitToTest = 1; digitToTest <= 9; digitToTest++) {
                for (let digitPosition = 1; digitPosition <= 3; digitPosition++) {
                    rowCsv += getDINPercentageCorrectDigitPosition(digitToTest, digitPosition, results) + ",";
                }
            }
            rowCsv += getAverageReactionTime(results).toFixed(2) + ",";
            rowCsv += "\n";
            if (includeRawData) {
                rowCsv += `, Set Index,Number Set, User Input, Output dB, isCorrect?\n`;
                for (let i = 0; i < results.length; i++) {
                    let isCorrect = checkDINCorrect(results[i]['numberSet'], results[i]['userInput']);
                    rowCsv += `, ${results[i]["setIndex"]},${("" + results[i]["numberSet"]).replaceAll(",", "_")}, ${("" + results[i]["userInput"]).replaceAll(",", "_")}, ${results[i]["outputdB"]}, ${isCorrect ? "yes" : "no"}, ${isCorrect?parseFloat(results[i]["reactionTime"]).toFixed(2):""}\n`;
                }
                rowCsv += "\n";
            }
            return rowCsv;
        } else if ((testRow['testType'] === "DIN" && projectType == "DIN_ADAPTIVE") || (testRow['testType'] === "CDIN" && projectType == "DIN_ADAPTIVE")) {
            //MARK: DIN_ADAPTIVE
            let results = JSON.parse(testRow["results"]);
            console.log("Results:" + JSON.stringify(results));
            let titles = ["ProjectID", "Ref No.", "Test Date", "Test Type", "Response mode", "Test Ear", "Test List", "Noise level (dB HL)",
                "SNR item 1-27", "SNR item 5-27", "SNR item 5-24", "SNR item 5-19", "SNR item 5-14", "SNR item 5-9", "Avg Reaction Time"];
            if (testRow['testType'] === "CDIN") {
                titles = ["ProjectID", "Ref No.", "Test Date", "Test Type", "Response mode", "Test Ear", "Test List", "Noise level (dB HL)",
                    "SNR item 1-12", "SNR item 5-12", "Avg Reaction Time"];
            }

            let rowCsv = "";

            if (firstTitle) {
                for (let i = 0; i < titles.length; i++) {
                    rowCsv += titles[i] + ",";
                }
                rowCsv += "\n";

                if (!includeRawData) {
                    firstTitle = false;
                }

            }

            rowCsv += testRow['projectID'] + ",";
            rowCsv += `${processRefNumber(testRow['refNo'])},`;
            rowCsv += moment(Math.floor(testRow['testDate'])).format("YYYY/MM/DD HH:mm") + ",";
            let testInfo = JSON.parse(testRow['testInfo']);
            rowCsv += testInfo['TestType'] + ",";
            rowCsv += "Adaptive" + ",";
            rowCsv += testInfo['TestEar'] + ",";
            rowCsv += testInfo['TestList'] + ",";
            rowCsv += "65,"; //Noise level (db HL)
            //get the average SNR.  Each output dB is subtracted by 65, and output average.  start and end are index from 0
            if (testRow['testType'] === "CDIN") {
                rowCsv += getDINAverageSNR(0, 11, results) + ",";
                rowCsv += getDINAverageSNR(4, 11, results) + ",";
            } else {
                rowCsv += getDINAverageSNR(0, 26, results) + ",";
                rowCsv += getDINAverageSNR(4, 26, results) + ",";
                rowCsv += getDINAverageSNR(4, 23, results) + ",";
                rowCsv += getDINAverageSNR(4, 18, results) + ",";
                rowCsv += getDINAverageSNR(4, 13, results) + ",";
                rowCsv += getDINAverageSNR(4, 8, results) + ",";
            }
            rowCsv += getAverageReactionTime(results).toFixed(2) + ",";
            rowCsv += "\n";
            if (includeRawData) {
                rowCsv += `, Set Index,Number Set, User Input, Output dB, Correct?, Reaction Time\n`;
                for (let i = 0; i < results.length; i++) {
                    let isCorrect = checkDINCorrect(results[i]['numberSet'], results[i]['userInput']);
                    rowCsv += `, ${results[i]["setIndex"]},${("" + results[i]["numberSet"]).replaceAll(",", "_")}, ${("" + results[i]["userInput"]).replaceAll(",", "_")}, ${results[i]["outputdB"]}, ${isCorrect ? "yes" : "no"}, ${isCorrect?parseFloat(results[i]["reactionTime"]).toFixed(2):""}\n`;
                }
                rowCsv += "\n";
            }
            return rowCsv;
        } else if (testRow['testType'] === "DDT" || testRow['testType'] === "CDDT") {

            let testInfo = JSON.parse(testRow['testInfo']);

            console.log("testInfo:" + JSON.stringify(testInfo));
            let results = JSON.parse(testRow["results"]);
            let titles = ["ProjectID", "Ref No.", "Test Date", "Test", "With Noise", "DigitNo",
                "Response mode", "Output level (dB HL)", "Percent correct_ALL digits", "Percent correct RIGHT", "Percent correct LEFT"];
            for (let i = 1; i <= 9; i++) {
                titles.push("BOTH/" + i + " (%)");
            }
            for (let i = 1; i <= 9; i++) {
                titles.push("RIGHT/" + i + " (%)");
            }
            for (let i = 1; i <= 9; i++) {
                titles.push("LEFT/" + i + " (%)");
            }


            let rowCsv = "";

            if (firstTitle) {
                for (let i = 0; i < titles.length; i++) {
                    rowCsv += titles[i] + ",";
                }
                rowCsv += "\n";
                if (!includeRawData) {
                    firstTitle = false;
                }
            }
            rowCsv += testRow['projectID'] + ",";
            rowCsv += `${processRefNumber(testRow['refNo'])},`;
            rowCsv += moment(Math.floor(testRow['testDate'])).format("YYYY/MM/DD HH:mm") + ",";
            rowCsv += "DDT" + ",";
            rowCsv += (testInfo['WithNoise'] == "true" ? "yes" : "no") + ",";
            rowCsv += testInfo['NumberCount'] + ",";
            rowCsv += testInfo['TestMethod'] + ",";
            rowCsv += testInfo['TestdB'] + ",";
            let bothDDTResult = getDDTPercentCorrect("both", results);
            let leftDDTResult = getDDTPercentCorrect("left", results);
            let rightDDTResult = getDDTPercentCorrect("right", results);

            rowCsv += bothDDTResult.pct + ",";
            rowCsv += rightDDTResult.pct + ",";
            rowCsv += leftDDTResult.pct + ",";

            for (let i = 1; i <= 9; i++) {
                rowCsv += rightDDTResult.digitItemCounts[i] == 0 ? "--," : (bothDDTResult.digitCorrectCounts[i] * 100 / bothDDTResult.digitItemCounts[i]).toFixed(2) + ",";
            }
            for (let i = 1; i <= 9; i++) {
                rowCsv += rightDDTResult.digitItemCounts[i] == 0 ? "--," : (rightDDTResult.digitCorrectCounts[i] * 100 / rightDDTResult.digitItemCounts[i]).toFixed(2) + ",";
            }
            for (let i = 1; i <= 9; i++) {
                rowCsv += rightDDTResult.digitItemCounts[i] == 0 ? "--," : (leftDDTResult.digitCorrectCounts[i] * 100 / leftDDTResult.digitItemCounts[i]).toFixed(2) + ",";
            }

            console.log("====> BOTH:" + JSON.stringify(bothDDTResult) + " LEFT:" + JSON.stringify(leftDDTResult) + " RIGHT:" + JSON.stringify(rightDDTResult));



            rowCsv += "\n";
            if (includeRawData) {
                rowCsv += `, Set Index,Output dB, Number Set Left, Number Set Right, User Input, Correct?\n`;
                for (let i = 0; i < results.length; i++) {
                    let isCorrect = checkDDTCorrect(testInfo['TestMethod'], results[i]["numberSetLeft"], results[i]["numberSetRight"], results[i]["userInput"]);
                    rowCsv += `"", ${results[i]["setIndex"]},"${results[i]["outputdB"]}", ${("" + results[i]["numberSetLeft"]).replaceAll(",", "_")}, ${("" + results[i]["numberSetRight"]).replaceAll(",", "_")}, ${("" + results[i]["userInput"]).replaceAll(",", "_")}, ${isCorrect ? "yes" : "no"}\n`;
                }
                rowCsv += "\n";
            }
            return rowCsv.replaceAll("undefined", "--");
        } else {
            return "\n";
        }
    }
    const generateCsv = (_jsonData) => {
        if (_jsonData.length === 0) {
            return "No data found";
        }

        _jsonData = _jsonData.sort((a, b) => a.testType > b.testType ? 1 : -1);
        let csvData = "";
        for (let i = 0; i < _jsonData.length; i++) {
            let row = _jsonData[i];
            csvData += renderTestResult(row);
        }
        // console.log("===> csvData:" + csvData);
        return csvData;
    }
    const downloadJson = async () => {
        // console.log("Selected project:" + selectedProject);
        let getResultBody = { "projectID": selectedProject };
        // console.log("URL:" + util.getUrl() + 'getTestResultListByProjectID')
        // console.log("Body:" + JSON.stringify(getResultBody));
        let response = await fetch(util.getUrl() + 'getTestResultListByProjectID', { method: 'POST', body: JSON.stringify(getResultBody) });
        let json = await response.json();
        // console.log(JSON.stringify(json));

        if (projectType.startsWith("DIN_")) {
            if (projectType === "DIN_ADAPTIVE") {
                json.data = json.data.filter(row => (row["testType"] === "DIN" || row["testType"] === "CDIN") && row["testInfo"].indexOf('"adaptive"') != -1);
            } else {
                json.data = json.data.filter(row => (row["testType"] === "DIN" || row["testType"] === "CDIN") && row["testInfo"].indexOf('"fixed"') != -1);
            }

            console.log(JSON.stringify(json.data));
        } else {
            json.data = json.data.filter(row => row["testType"] === projectType || row["testType"] === ("C" + projectType));
        }

        setDownloadText("Download");
        return json.data;
    }
    const downloadFile = async () => {
        setDownloadText("Downloading...");
        const element = document.createElement("a");
        let jsonDataToRender = await downloadJson();
        // console.log(projectType + "\n" + jsonDataToRender);
        let csvData = generateCsv(jsonDataToRender);
        const file = new Blob([csvData], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = projectType + ".csv";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        firstTitle = true;
    }
    const handleProjectTypeChange = (value) => {
        console.log(value.target.value);
        setProjectType(value.target.value);
    }
    const handleProjectChange = (value) => {
        console.log(value.target.value);
        setSelectedProject(value.target.value);
    }
    return (
        <>
            <Paper style={{ padding: '20px', margin: '20px', width: "80vw" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <Box sx={{ justifyContent: 'center', flexDirection: 'column', alignItems: "center" }}>
                            <CloudDownloadIcon style={{ width: "10vw", height: "10vh", color: "#1976d2" }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="project-id-label">Project</InputLabel>
                            {(projectList!=null && projectList.length>0)?<Select
                                labelId="project-label"
                                id="project"
                                value={selectedProject}
                                label="Project"
                                onChange={handleProjectChange}
                            >
                                {
                                    projectList.map((project, index) => {
                                        return (
                                            <MenuItem key={"menuItem" + project.projectID} value={project.projectID}>{project.projectID}</MenuItem>
                                        ) 
                                    })
                                }
                            </Select>:<span>Loading Project List</span>}
                
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ display: selectedProject == "" ? "none" : "block" }} >
                        <FormControl fullWidth>
                            <InputLabel id="project-id-label">Project Type</InputLabel>
                            <Select
                                labelId="project-id-label"
                                id="project-id"
                                value={projectType}
                                label="Project Type"
                                onChange={handleProjectTypeChange}
                            >
                                <MenuItem value={"HST"}>Hearing Screening Test</MenuItem>
                                <MenuItem value={"HTT"}>Hearing Threshold Test</MenuItem>
                                <MenuItem value={"DIN_FIXED"}>Digit in Noise Test (Fixed)</MenuItem>
                                <MenuItem value={"DIN_ADAPTIVE"}>Digit in Noise Test (Adaptive)</MenuItem>
                                <MenuItem value={"DDT"}>Dichotic Digit Test</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox checked={includeRawData} onChange={(e) => setIncludeRawData(e.target.checked)} inputProps={{ 'aria-label': 'controlled' }} />} label="Include raw data" />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Button variant="outlined" disabled={downloadText!="Download"} onClick={downloadFile}>{downloadText}</Button>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{
                padding: '20px',
                margin: '20px',
                width: "80vw",
                display: props.userType === "ADMIN" ? "block" : "none"
            }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <FormControl fullWidth>
                            <Button variant="outlined" onClick={() => props.setCurrentPage("admin")}>Go To Administration</Button>
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={12}>
                        {JSON.stringify(selectedProject)}
                    </Grid> */}
                </Grid>

            </Paper>
        </>
    )
}

export default DownloadPage;