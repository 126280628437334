const urlprod = 'https://dinddt.apix.link/';
const urldev = 'https://26y2ujx7e4.execute-api.ap-east-1.amazonaws.com/Prod/';

const isDev = false;
const getUrl = () => {
    if(isDev) {
        return urldev;
    }else{
        return urlprod;
    }
}

export default {getUrl}